@use "arts/assets/variables" as *;

.item {
  margin: 15px 0 0 0;
  padding-bottom: 15px;
  border-bottom: 1px solid $checkout-line;
  font-size: 12px;
  font-family: $proxima-font-family;

  @media (min-width: $m-w-4) {
    font-size: 14px;
  }

  p {
    margin: 0;
    padding: 0;
    line-height: 150%;
  }

  .tracking {
    flex: 1;
  }

  &.noBorder {
    border-bottom: none;
    font-size: 15px;
    margin-top: 0;

    .info {
      font-size: 15px !important;
      a {
        text-decoration: underline;
        color: #000;
      }
    }
  }
}


.discount {
  display: flex;

  h4, .info {
    flex: 1;
  }

  h4 {
    margin: 0;
    padding: 0 0 0 15px;
    border-left: 1px solid #000;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: normal;

    small {
      letter-spacing: 1px;
      color: $grey;
      text-transform: none;
      font-size: 14px;
    }

    @media (min-width: $m-w-4) {
      font-size: 18px;

      small {
        font-size: 16px;
      }
    }
  }

  .info {
    padding-right: 15px;
  }

  a {
    color: #000;
    text-decoration: underline;

    &:hover, &:active {
      color: #000;
    }
  }
}

span.new {
  color: $red;
}

span.old {
  text-decoration: line-through;
}

.classic {
  display: flex;

  .image {
    width: 67px;
    margin-right: 30px;

    @media (min-width: 400px) {
      width: 114px;
      margin-right: 43px;
    }

    @media (min-width: $m-w-2) {
      margin-right: 69px;
    }

    @media (min-width: $m-w-3) {
      width: 131px;
      margin-right: 89px;
    }

    @media (min-width: $m-w-4) {
      width: 180px;
      margin-right: 126px;
    }

    a {
      display: block;
    }
  }

  .info {
    font-family: $proxima-font-family;
    font-size: 12px;
    flex: 1;

    @media (min-width: $m-w-4) {
      font-size: 14px;
    }

    h4 {
      padding: 0;
      margin: 0;

      a {
        font-weight: 600;
        color: #000;
        font-family: $ff-crimson-font-family;
        text-decoration: none;
        font-size: 14px;
        line-height: 125%;
        letter-spacing: 0.32px;

        @media (min-width: $m-w-2) {
          font-size: 16px;
        }

        @media (min-width: $m-w-3) {
          font-size: 16px;
        }

        @media (min-width: $m-w-4) {
          font-size: 18px;
        }

        &:hover {
          color: #000;
          text-decoration: underline;
        }
      }
    }

    .by {
      margin-bottom: 9px;

      @media (min-width: $m-w-4) {
        margin-bottom: 12px;
      }

      a {
        color: #000;

        &:hover, &:active {
          color: #000;
          text-decoration: underline;
        }
      }
    }

    p {
      color: #000;

      span {
        font-weight: bold;
      }

      span.price {
        color: #000;
        font-weight: normal;
      }
    }
  }
}

:global(.rr) .classic .image {
  @media (min-width: $m-w-4) {
    width: 131px;
    margin-right: 89px;
  }
}

.narrow {
  .classic {
    .image {
      margin-right: 9px;

      @media (min-width: 400px) {
        margin-right: 15px;
      }

      @media (min-width: $m-w-2) {
        margin-right: 21px;
      }

      @media (min-width: $m-w-3) {
        margin-right: 27px;
      }

      @media (min-width: $m-w-4) {
        margin-right: 33px;
      }
    }
  }
}

.remove {
  margin-right: 15px;

  button {
    outline: none;
    border: 0;
    background: #fff;
    color: #000;
    text-decoration: underline;
    font-family: $proxima-font-family;
    font-size: 12px;
    flex: 1;

    @media (min-width: $m-w-4) {
      font-size: 14px;
    }
  }
}

.error {
  color: red;
  padding: 6px 0;
}
