@use "arts/assets/variables" as *;

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $modal-layer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);

  &.active {
    background-color: rgba(255, 255, 255, 0.7);
    transition: background-color 0.3s;
  }
}

.root {
  width: $modal-width;

  @include modal-window;
}

.quickShop {
  width: auto;
  @include modal-window;
  display: flex;
  flex-direction: column;
  max-height: 81%;

  .contentDefault {
    overflow-y: auto;
    flex: 1;
  }
}

.wallScale {
  height: auto;
  width: 80%;

  @include modal-window;

  @media (max-width: $w-1-mobile) {
    width: 100%;
  }
}

.scale {
  height: auto;
  width: 80%;
  max-width: 958px;

  @include modal-window;

  @media (max-width: $w-1-mobile) {
    width: 100%;
  }
}

.wall {
  width: 1920px;
  max-width: 80%;

  @include modal-window;
}

.header {
  display: flex;
  flex-direction: row !important;
  @include page-title;

  h1 {
    font-family: $proxima-font-family;
    font-weight: 300;
    margin-bottom: 0 !important;
  }

  .placeholder {
    flex: 1;
  }

  .cancel {
    border: none;
    background-color: #fff;
    font-family: $proxima-font-family;
    font-size: 27px;

    // border-radius: 50% 50%
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    color: #000;
  }
}

.zoom {
  background-color: #424242;

  .header {
    position: absolute;
    width: 100%;
    z-index: 1;
    .cancel {
      height: 63px;
      width: 63px;
      font-size: 75px;
      background: transparent;
      color: #f1f1f1;
      text-shadow: 0 0 10px #424242;
    }
  }

  padding: 0;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;


  .contentDefault {
    margin: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;


  }

  div:last-child {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
}

.videoContainer {
  width: 100%;

  video {
    width: 100%;
  }
}
