@use "arts/assets/variables" as *;

//SSR styles reset

.arts {
  box-sizing: border-box;

  * {
    line-height: initial;
  }
}

.container {
  margin: 0 auto;
  width: 940px;

  @media (min-width: 1200px) {
    width: 1170px;
  }
}


.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px auto;
}

.container20 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin inner-container {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 auto;
  justify-content: space-between;
}

.innerContainer20 {
  @include inner-container;
  @include width-keeper;
}

.innerContainerArt {
  @include inner-container;
  @include width-keeper-art;
}

ul.gridContainer4inRow {
  display: grid;

  margin: 0;
  list-style-type: none;
  grid-template-columns: $grid-w4-3;
  grid-column-gap: $grid-cg-3;
  grid-row-gap: $grid-rg-3;
  margin-top: $mobile-padding;

  @media (min-width: 562px) {
    grid-template-columns: $grid-w4-2 $grid-w4-2;
    grid-column-gap: $grid-cg-2;
    grid-row-gap: $grid-rg-2;
  }

  @media (min-width: 712px) {
    grid-template-columns: $grid-w4-1 $grid-w4-1 $grid-w4-1;
    grid-column-gap: $grid-cg-1;
    grid-row-gap: $grid-rg-1;
  }

  @media (min-width: $w-1-mobile) {
    grid-template-columns: $grid-w4-1 $grid-w4-1 $grid-w4-1 $grid-w4-1;
    grid-column-gap: $grid-cg-1;
    grid-row-gap: $grid-rg-1;
  }

  @media (min-width: $m-w-2) {
    grid-template-columns: $grid-w4-2 $grid-w4-2 $grid-w4-2 $grid-w4-2;
    grid-column-gap: $grid-cg-2;
    grid-row-gap: $grid-rg-2;
  }

  @media (min-width: $m-w-3) {
    grid-template-columns: $grid-w4-3 $grid-w4-3 $grid-w4-3 $grid-w4-3;
    grid-column-gap: $grid-cg-3;
    grid-row-gap: $grid-rg-3;
  }

  @media (min-width: $m-w-4) {
    grid-template-columns: $grid-w4-4 $grid-w4-4 $grid-w4-4 $grid-w4-4;
    grid-column-gap: $grid-cg-4;
    grid-row-gap: $grid-rg-4;
  }

}

ul.gridContainer1inRow {
  display: grid;
  margin: 0;
  list-style-type: none;

  grid-template-columns: auto;
  grid-column-gap: $grid-cg-1;
  grid-row-gap: $grid-rg-1;

  li {
    height: $grid-h-1;
    position: relative;

    &.wall {
      height: $grid-hw-1;
    }
  }

  @media (max-width: 1024px) {
    width: 90%;
  }

  @media (min-width: 490px) {
    li {
      height: $grid-h-2;
      position: relative;

      &.wall {
        height: $grid-hw-2;
      }
    }
  }

  @media (min-width: $w-1-mobile) {
    grid-template-columns: $grid-w-1;
    grid-column-gap: $grid-cg-1;
    grid-row-gap: $grid-rg-1;

    li {
      height: $grid-h-mobile;
    }
  }

  @media (min-width: $m-w-2) {
    grid-template-columns: $grid-w-2;
    grid-column-gap: $grid-cg-2;
    grid-row-gap: $grid-rg-2;

    li {
      height: $grid-h-2;
    }

    &.wall {
      height: $grid-hw-2;
    }
  }

  @media (min-width: $m-w-3) {
    grid-template-columns: $grid-w-3;
    grid-column-gap: $grid-cg-3;
    grid-row-gap: $grid-rg-3;

    li {
      height: $grid-h-3;

      &.wall {
        height: $grid-hw-3;
      }
    }
  }

  @media (min-width: $m-w-4) {
    grid-template-columns: $grid-w-4;
    grid-column-gap: $grid-cg-4;
    grid-row-gap: $grid-rg-4;

    li {
      height: $grid-h-4;

      &.wall {
        height: $grid-hw-4;
      }
    }
  }
}

ul.gridContainer3inRow {
  display: grid;
  width: 100%;
  margin: 0;
  margin-top: 12px;
  list-style-type: none;

  grid-template-columns: auto auto;
  grid-column-gap: $grid-cg-1;
  grid-row-gap: $grid-rg-1;

  > li {
    height: $grid-h-1;
    position: relative;

    &.wall {
      height: $grid-hw-1;
    }

    &.moreLike {
      grid-column: 1 / 3;
      height: $more-like-this-height + 94px;
      overflow-x: hidden;
      padding: 0 21px;
    }
  }

  @media (min-width: 490px) {
    > li {
      height: $grid-h-2;
      position: relative;

      &.wall {
        height: $grid-hw-2;
      }
    }
  }

  @media (min-width: $w-1-mobile) {
    grid-template-columns: $grid-w-1 $grid-w-1 $grid-w-1;
    grid-column-gap: $grid-cg-1;
    grid-row-gap: $grid-rg-1;
    > li {
      height: $grid-h-mobile;

      &.moreLike {
        grid-column: 1 / 4;
        height: $more-like-this-height + 94px;
        padding: 0 24px;
      }
    }
  }

  @media (min-width: $m-w-2) {
    grid-template-columns: $grid-w-2 $grid-w-2 $grid-w-2;
    grid-column-gap: $grid-cg-2;
    grid-row-gap: $grid-rg-2;

    > li {
      height: $grid-h-2;

      &.wall {
        height: $grid-hw-2;
      }
    }
  }

  @media (min-width: $m-w-3) {
    grid-template-columns: $grid-w-3 $grid-w-3 $grid-w-3;
    grid-column-gap: $grid-cg-3;
    grid-row-gap: $grid-rg-3;

    > li {
      height: $grid-h-3;

      &.wall {
        height: $grid-hw-3;
      }
    }
  }

  @media (min-width: $m-w-4) {
    grid-template-columns: $grid-w-4 $grid-w-4 $grid-w-4;
    grid-column-gap: $grid-cg-4;
    grid-row-gap: $grid-rg-4;

    > li {
      height: $grid-h-4;

      &.wall {
        height: $grid-hw-4;
      }
    }
  }
}

ul.gridContainer2inRow {
  @include grid2InRow;
}

a.actionButton {
  @include action-button;
}

.gridCheckout {
  margin: 0;
  padding: 12px $mobile-padding 25px $mobile-padding;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;

  @include width-keeper;

  @media (min-width: $w-1-mobile) {
    flex-direction: row;
    padding: 12px 0 25px 0;
  }

  .left {
    width: 100%;

    @media (min-width: $w-1-mobile) {
      width: 515px;
    }

    @media (min-width: $m-w-2) {
      width: 618px;
    }

    @media (min-width: $m-w-3) {
      width: 714px;
    }

    @media (min-width: $m-w-4) {
      width: 850px;
    }
  }

  .right {
    width: 100%;

    @media (min-width: $w-1-mobile) {
      width: 284px;
    }

    @media (min-width: $m-w-2) {
      width: 339px;
    }

    @media (min-width: $m-w-3) {
      width: 386px;
    }

    @media (min-width: $m-w-4) {
      width: 698px;
    }
  }

  .button {
    background: #000;
    color: #fff;
    @include sparsed-button;
  }

  .whiteButton {
    background: #fff;
    color: #000;
    @include sparsed-button;
  }
}

.zoomInIcon {
  @media (max-width: $art-page-mobile-break) {
    display: none !important;
  }
}


.thanksForAction {
  width: 720px;

  background-color: $white;
  box-shadow: $box-shadow-medium;
  padding: 9px 12px 48px 12px;
  text-align: center;

  @media (max-width: 582px) {
    position: absolute;
    top: 50%;
    height: 50%;
    bottom: 0;
    width: 100%;
  }

  @media (max-width: $w-1-mobile) {
    padding-top: $mobile-padding;
    paddin-bottom: $mobile-padding;
  }

  h2 {
    font-family: $web-pro-font-family;
    font-size: 39px;
    line-height: 52px;
    font-weight: normal;
    margin: 0 0 12px 0;
  }

  p {
    font-size: 21px;
    letter-spacing: 0.5px;
    font-weight: 200;
    margin: 0 0 3px 0;
  }

  .div {
    width: 108px;
    margin: 30px auto;
    border-top: 1px solid #000;
  }

  .action {
    margin: 30px 0;

    button {
      min-width: 210px;
      background: #000;
      color: #fff;
      @include sparsed-button;
    }
  }
}

.footerText {
  svg {
    height: 14px;
    @media (min-width: $w-1-mobile) {
      height: 20px;
    }
    @media (min-width: $m-w-2) {
      height: 22px;
    }

    @media (min-width: $m-w-3) {
      height: 35px;
    }

    @media (min-width: $m-w-4) {
      height: 57px;
    }
  }

  h3 {
    font-family: $proxima-font-family;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.06em;
    margin: 0;
    padding: 0;
    @media (min-width: $m-w-2) {
      font-size: 18px;
    }

    @media (min-width: $m-w-3) {
      font-size: 21px;
    }

    @media (min-width: $m-w-4) {
      font-size: 27px;
    }
  }
}

.headerText {
  h1 {
    font-family: $proxima-font-family;
    margin: 0;
    padding: 0 0 5px 0;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 22px;
    letter-spacing: 0.06em;

    @media (min-width: $w-1-mobile) {
      font-size: 20px;
      padding: 4px 0;
    }

    @media (min-width: $m-w-2) {
      font-size: 23px;
    }

    @media (min-width: $m-w-3) {
      font-size: 26px;
      padding: 10px 0;
    }

    @media (min-width: $m-w-4) {
      font-size: 38px;
    }
  }

  h2 {
    padding-bottom: 10px;
    @include headerSubtitleText;
  }
}

.skeleton {
  position: relative;
  overflow: hidden;
  background-color: #ffffff;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(226, 229, 231, 0) 0,
      rgba(226, 229, 231, 0.2) 20%,
      rgba(226, 229, 231, 0.5) 60%,
      rgba(226, 229, 231, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
