@use "arts/assets/variables" as *;

$popup-width: 488px;

.added {
  position: absolute;
  width: 488px;
  font-family: $proxima-font-family;
  text-transform: none;

  position: absolute;
  right: 0;
  background: #fff;
  z-index: 2;
  top: 28px;
  box-shadow: $box-shadow-medium;

  h3 {
    font-weight: normal;
    font-size: 16px;
    padding: 9px $mobile-padding 9px $mobile-padding;
    margin: 0;
    display: flex;
    justify-content: space-between;

    button {
      background: #fff;
      outline: none;
      border: none;
      color: #000;
      font-size: 26px;
      line-height: 15px;
      padding: 4px 0 4px;
      margin: 0;
    }
  }

  @media (max-width: $w-1-mobile) {
    top: 40px;
  }

  @media (max-width: $popup-width) {
    width: 100%;
  }
}


.items {
  border-top: 1px solid $checkout-line;
  padding: 0 $mobile-padding;
  h5 {
    font-weight: normal;
    font-size: 27px;
    padding: 24px 0;
    text-align: center;
  }
}

.subtotal {
  font-weight: bold;
  font-size: 18px;
  display: flex;
  padding: 9px $mobile-padding;
  font-size: 14px;
  justify-content: space-between;
}

.cart {
  border-top: 1px solid #000;
  display: flex;
  height: 74px;
  div, .checkout {
    flex: 1;
    align-items: center;
  }

  a.view {
    display: flex;
    padding: 0 $mobile-padding;
    font-size: 14px;
    text-decoration: underline;
    color: #000;
    flex: 1;
    align-items: center;
  }

  .checkout {
    font-size: 16px;
    display: flex;
    color: #fff;
    background: $add_to_cart_color;
    justify-content: center;
    letter-spacing: 0.06em;
  }
}
