@use "arts/assets/variables" as *;

.locale {
  margin-bottom: 32px;
  padding: 0;
  font-family: $proxima-font-family;
  text-align: center;

  @media (min-width: $w-0-mobile) {
    padding: 32px 58px;
  }

  @media (min-width: 670px) {
    padding: 32px 98px;
  }

  @media (min-width: $w-1-mobile) {
    padding: 32px 254px;
  }

  @media (min-width: $m-w-3) {
    padding: 32px 265px;
  }

  a {

    h5 {
      font-size: 28px;
      line-height: 36px;
      text-decoration: underline;
      font-weight: normal;
      text-transform: uppercase;

      @media (max-width: 375px) {
        font-size: 21px;
        line-height: 32px;
      }
    }

    p {
      font-size: 18px;
    }
  }
}

.current {
  margin-top: 32px;
  font-size: 18px;

  svg {
    height: 20px;
    display: inline;
    vertical-align: bottom;
  }
}

.locale a, .current a {
  display: block;
  color: #000;
  text-decoration: none;
  &:hover, &:active, &:focus {
    color: #000;
  }
}

.current a {
  font-weight: bold;
  text-decoration: underline;
}

.div {
  width: 60px;
  margin: 39px auto;
  border-bottom: 1px solid #000;
}

.site {
  font-weight: bold;
  align-items: baseline !important;
}
