@use "arts/assets/variables" as *;

.label, .value {
  left: 0;
}

.summary {
  @include summary;

  li.total {
    margin-top: 12px;
    border-top: 1px solid $checkout-line;
    padding: 12px 0 0;

    span {
      font-weight: bold;
      font-size: 18px;

      @media (min-width: $m-w-4) {
        font-size: 20px;
      }
    }

    span.calculating {
      font-size: 14px;
      font-weight: normal;

      @media (min-width: $m-w-4) {
        font-size: 16px;
      }
    }
  }
}

.tos {
  padding: 3px 0;
  font-size: 12px;
  a {
    color: #000;
    text-decoration: underline;
    &:hover, &:active {
      text-decoration: underline;
      color: #000;
    }
  }
}

.checkout {
  display: flex;
  flex-direction: column;

  > div {
    flex: 1;
  }

  .surround {
    border-top: 1px solid $checkout-line;
    margin: 7px 0 0;
    padding: 7px 0;
    flex: 1;
    display: flex;
  }

  .paypal {
    padding: 0 0 7px 0;
    flex: 1;
    display: flex;

  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 35px;
  }

  .paypal, .button {
    width: 200px;

    @media (max-width: 400px) {
      width: 100%;
    }

    @media (min-width: 340px) {
      height: 45px;
    }

    @media (min-width: 400px) {
      height: 35px;
    }

    @media (min-width: $m-w-3) {
      width: 250px;
    }

    @media (min-width: $m-w-4) {
      width: 300px;
      height: 45px;
    }
  }

  .savingOrder {
    padding: 7px 0;
    flex: 1;
    display: flex;

    span {
      margin-left: 7px;
    }
  }


  .final {
    border-top: 1px solid $checkout-line;
    padding-top: 12px;
    font-size: 12px;
    font-family: $proxima-font-family;

    @media (min-width: $m-w-4) {
      font-size: 14px;
      margin-top: 24px 0;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        display: block !important;
        margin-bottom: 0;
        line-height: 150%;
      }
    }

    button {
      display: inline;
      border: none;
      font-family: $proxima-font-family;
      font-size: 12px;
      background: #fff;
      color: #000;
      text-decoration: underline;
      outline: none;
      border: none;
      margin: 0;
      padding: 0;

      @media (min-width: $m-w-4) {
        font-size: 14px;
      }
    }
  }
}

.affirmPromo {
  color: $grey;
  font-size: 14px;
  margin-bottom: 18px;

  @media (min-width: $m-w-4) {
    font-size: 16px;
  }

  a {
    color: $grey;
    display: block;
    color: #000;
    text-decoration: underline;

    &:hover, &:active {
      color: #000;
      text-decoration: underline;
    }
  }
}
