@use "sass:math";

$hp-max: 2560px !default;

// Screen size boundaries
$hp-break: 600px !default;

// Shadows
$box-shadow-medium: 0 3px 7px rgba(0, 0, 0, 0.3) !default;
$nav-shadow: 0 7px 16px -7px rgba(0, 0, 0, 0.3) !default  ;

$black: #000 !default;

// Stack order
$modal-layer: 1050 !default;

// Palette
$white: #fff !default;
$yellow: #ece853 !default;
$light-grey: #eeeff1 !default;
$darkgrey: #3b3b3b !default;
$grey: #8e8e8a !default;
$grey-border: #c6c6c6 !default;
$red: #ec4b4a !default;
$warning: #ff0200;

$light-brown: #dccfb9 !default;
$light-brown-text: #000 !default;

$frame_border: #ddd !default;

$add_to_cart_color: #38597d !default;
$add_to_cart_text_color: #38597d !default;

$dark: #3b3b3b !default;
$checkout-line: #e3e3e3 !default;

// Font Sizes
$font-size-heading: 17px !default;
$font-size-lg: 14px !default;
$font-size-small: 13px !default;
$font-size-tiny: 11px !default;
$font-size-tiny-mobile: 10px !default;

// Font Families
$proxima-font-family: proxima-nova, sans-serif !default;
$web-pro-font-family: ff-meta-serif-web-pro, serif !default;
$ff-crimson-font-family: "Crimson Text", serif !default;


$header-banner-height-mobile: 49px !default;
$header-banner-height: 81px !default;
$header-banner-height-w4: 91px !default;

$navigation-height: 33px !default;
$navigation-height-w4: 42px !default;

$search-height: 48px !default;

$filters-top-mobile: $header-banner-height-mobile !default;
$filters-top: $navigation-height + $header-banner-height + 1px !default;
$filters-top-w4: $navigation-height-w4 + $header-banner-height-w4 + 2px !default;

$mobile-arrow-top: calc(15.73px + 32.83vw) !default;

  $modal-width: 976px !default;

$art-page-mobile-break: 712px !default;

// screen break points
$m-w-2: 1366px !default;
$m-w-3: 1600px !default;
$m-w-4: 2560px !default;

// item width
$grid-w-1: 280px !default;
$grid-w-2: 334px !default;
$grid-w-3: 386px !default;
$grid-w-4: 546px !default;

// item height
$grid-h-mobile: 398px !default;
$grid-h-1: 264px !default;
$grid-h-2: 395px !default;
$grid-h-3: 449px !default;
$grid-h-4: 636px !default;

// wall height
$grid-hw-1: 232px !default;
$grid-hw-2: 366px !default;
$grid-hw-3: 287px !default;
$grid-hw-4: 412px !default;

// column (h) padding
$grid-cg-1: 30px !default;
$grid-cg-2: 40px !default;
$grid-cg-3: 49px !default;
$grid-cg-4: 61px !default;

// row (v) padding
$grid-rg-1: 26px !default;
$grid-rg-2: 26px !default;
$grid-rg-3: 26px !default;
$grid-rg-4: 45px !default;

// screen width
$w-1: $grid-w-1 * 3 + $grid-cg-1 * 2 !default;
$w-2: $grid-w-2 * 3 + $grid-cg-2 * 2 !default;
$w-3: $grid-w-3 * 3 + $grid-cg-3 * 2 !default;
$w-4: $grid-w-4 * 3 + $grid-cg-4 * 2 !default;

$mobile-padding: 21px !default;
$negative-mobile-padding: -1 * $mobile-padding !default;
$w-1-mobile: $w-1 + 2 * $mobile-padding !default;
$w-1-mobile-art: 850px !default;
$w-0-mobile: 500px !default;

$grid-w4-1: math.div(($w-1 - $grid-cg-1 * 3),  4) !default;
$grid-w4-2: math.div(($w-2 - $grid-cg-2 * 3) , 4) !default;
$grid-w4-3: math.div(($w-3 - $grid-cg-3 * 3) , 4) !default;
$grid-w4-4: math.div(($w-4 - $grid-cg-4 * 3) , 4) !default;

// image vertical padding
$delta-1: 4px !default;
$delta-2: 4px !default;
$delta-bottom-3: 9px !default;
$delta-bottom-4: 15px !default;

$sub-section-height: 14px !default;

$row-margin: 12px !default;

$more-like-this-height: 170px !default;

$wall-picture-shadow: 4px 6px 19px -5px rgba(0,0,0,0.75) !default;

  @mixin width-keeper-no-mobile {
  & {
    width: $w-1;
  }

  @media (min-width: $m-w-2) {
    width: $w-2;
  }

  @media (min-width: $m-w-3) {
    width: $w-3;
  }

  @media (min-width: $m-w-4) {
    width: $w-4;
  }

  @media (max-width: $w-1-mobile) {
    width: 100%;
  }
}

@mixin width-keeper {
  @include width-keeper-no-mobile;

  @media (max-width: $w-1-mobile) {
    padding: 0 $mobile-padding 0 $mobile-padding;
  }
}

$w1-art: 846px !default;
$w1-art-size-selection: 390px !default;
$w2-art: 1138px !default;
$w2-art-size-selection: 539px !default;
$w3-art: 1405px !default;
$w3-art-size-selection: 669px !default;
$w4-art: 1970px !default;
$w4-art-size-selection: 924px !default;

@mixin width-keeper-art {
  width: $w1-art;

  @media (max-width: $w-1-mobile) {
    width: 100%;
    padding: 0 $mobile-padding 0 $mobile-padding;
  }

  @media (min-width: $w2-art) {
    width: $w2-art;
  }

  @media (min-width: $w3-art) {
    width: $w3-art;
  }

  @media (min-width: $w4-art) {
    width: $w4-art;
  }
}

@mixin navigational-links {
  h5, a {
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-family: $proxima-font-family;
    color: #000;
    line-height: 200%;

    @media (min-width: $m-w-2) {
      font-size: 14px;
    }

    @media (min-width: $m-w-4) {
      font-size: 16px;
    }

    @media (max-width: $w-1-mobile) {
      font-size: 14px;
    }
  }

  h5 {
    border: none;
    outline: none;
    background: #fff;
    font-weight: bold;
    text-transform: uppercase;
    margin: 12px 0;
    display: block;
    cursor: auto;

    &:first-child {
      margin-top: 0px;
    }


    @media (min-width: $m-w-2) {
      margin: 14px 0;
    }

    @media (min-width: $m-w-4) {
      margin: 16px 0;
    }

    @media (max-width: $w-1-mobile) {
      margin: 0;
      line-height: 300%;
    }

    a {
      text-decoration: underline;
    }
  }

  a {
    text-decoration: none;

    &:hover, &:active {
      text-decoration: underline;
      color: #000;
    }

    display: flex;
    align-items: center;

    svg {
      width: 12px;
      margin-right: 6px;

      @media (min-width: $m-w-2) {
        width: 14px;
        margin-right: 7px;
      }

      @media (min-width: $m-w-4) {
        width: 16px;
        margin-right: 8px;
      }

      @media (max-width: $w-1-mobile) {
        width: 14px;
        margin-right: 7px;
      }
    }
  }

}

@mixin summary {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      margin-bottom: 12px;

      span {
        width: 50%;
        font-size: 14px;
        color: #000;
        font-family: $proxima-font-family;

        @media (min-width: $m-w-4) {
          font-size: 16px;
        }
      }

    }
  }
}

$info-height-mobile: 96px !default;
$info-height: 84px !default;
$info-height-4: 124px !default;

@mixin ellipsis {
  & {
    position: relative;
    span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 0;
    }
  }
}

@mixin ellipsis-hover {
  & {
    text-decoration: none;
    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }
}

@mixin small-text {
  font-size: 12px;
  font-family: $proxima-font-family;
  line-height: 16px !important;
  letter-spacing: 0.24px;

  @media (min-width: $m-w-4) {
    font-size: 14px;
  }
}

@mixin large-text {
  & {
    line-height: 20px;
    font-size: 16px;
    font-family: $ff-crimson-font-family;
    font-weight: 600;
    letter-spacing: 0.32px;
  }

  @media (min-width: $m-w-4) {
    font-size: 18px;
    line-height: 24px;
  }
}

@mixin header-tag {
  margin: $sub-section-height 0 !important;
  padding: 0;
  display: flex;
  align-items: center;
  font-family: $ff-crimson-font-family;
  font-weight: normal;
  font-size: 18px;

  @media (min-width: $w-1-mobile) {
    padding: 0;
    font-size: 30px;
  }
}

@mixin page-title {
  h1 {
    @include header-tag;
  }
  & {
    flex-direction: column;
    box-sizing: border-box;
  }
}

@mixin page-title-h2 {
  h2 {
    @include header-tag;
  }
  & {
    flex-direction: column;
    box-sizing: border-box;
  }
}

@mixin page-title-inner {
  flex-direction: column;
  box-sizing: border-box;

  h1 {
    margin: $sub-section-height 0;
    padding: 0;
    display: flex;
    align-items: center;
    font-family: $ff-crimson-font-family;
    font-weight: normal;
    font-size: 30px;
  }
}

$two-columns-gap-1: 30px !default;
$two-columns-gap-2: 40px !default;
$two-columns-gap-3: 50px !default;
$two-columns-gap-4: 60px !default;

$grid-f1: 103px !default;
$grid-f1w: 163px !default;
$grid-fg-1: 30px !default;
$grid-fs-1: 203px !default;

$grid-f2: 125px !default;
$grid-f2w: 208px !default;
$grid-fg-2: 31px !default;
$grid-fs-2: 248px !default;

$grid-f3: 119px !default;
$grid-f3w: 160px !default;
$grid-fg-3: 51px !default;
$grid-fs-3: 327px !default;

$grid-f4: 181px !default;
$grid-f4w: 305px !default;
$grid-fg-4: 63px !default;
$grid-fs-4: 458px !default;

@mixin popularArtGrid {
  grid-row-gap: 60px;

  li {
    border-bottom: 1px solid #000;

    a.imgL {
      display: block;
    }

    &.empty {
      border-bottom: none;
    }

    h3 {
      margin: 31px 0 21px 0;
      font-family: $ff-crimson-font-family;
      font-size: 20px;
      color: #000;
      font-weight: 600;

      a {
        color: #000;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    p {
      font-family: $proxima-font-family;
      font-size: 14px;
      line-height: 20px;
      margin: 0 0 10px 0;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

@mixin grid2InRow {
  margin: 0;
  padding: 12px 0;
  display: grid;

  list-style-type: none;

  grid-template-columns: auto;
  grid-column-gap: $two-columns-gap-1;

  @media (min-width: $w-1-mobile) {
    padding: 12px 0 25px 0;
    grid-template-columns: (math.div(($w-1 - $two-columns-gap-1), 2)) (math.div(($w-1 - $two-columns-gap-1), 2));
    grid-column-gap: $two-columns-gap-1;
  }

  @media (min-width: $m-w-2) {
    grid-template-columns: (math.div(($w-2 - $two-columns-gap-2), 2)) (math.div(($w-2 - $two-columns-gap-2), 2));
    grid-column-gap: $two-columns-gap-2;
  }

  @media (min-width: $m-w-3) {
    grid-template-columns: (math.div(($w-3 - $two-columns-gap-3), 2)) (math.div(($w-3 - $two-columns-gap-3), 2));
    grid-column-gap: $two-columns-gap-3;
  }

  @media (min-width: $m-w-4) {
    grid-template-columns: (math.div(($w-4 - $two-columns-gap-4), 2)) (math.div(($w-4 - $two-columns-gap-4), 2));
    grid-column-gap: $two-columns-gap-4;
  }
}

@mixin expandable-arrow {
  display: block;
  position: relative;
  cursor: pointer;
  width: 100%;

  &::after {
    content: '›';
    font-size: 200%;
    font-weight: 400;
    position: absolute;
    right: 0;
    transform: rotate(90deg);
    font-family: $proxima-font-family;
    margin-top: 2px;
  }

  &.expanded {
    &::after {
      content: '‹';
    }
  }
}

@mixin any-button {
  padding: 6px 30px;
  text-transform: uppercase;
  border: none;
  text-decoration: none;
  font-family: $proxima-font-family;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  margin: 2px 0 15px;
  display: inline-block;
}

@mixin white-button {
  background: #fff;
  color: #000;

  &:hover, &:active, &:focus {
    color: #000;
  }

  @include any-button;
}

@mixin action-button {
  background: #000;
  color: #fff;

  &:hover, &:active, &:focus {
    color: #fff;
  }

  @include any-button;
}

@mixin modal-window {
  background-color: $white;
  box-shadow: $box-shadow-medium;
  padding: 18px 18px;
}

@mixin custom-chekbox {
  align-items: normal !important;
  margin-bottom: 0;
  font-size: 14px;
  display: flex;
  position: relative;
  outline: none;

  input {
    margin: 0 6px 0 0;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    appearance: none;
    border: 1px solid #000;
    outline: none;
    cursor: pointer;

    &:disabled {
      border: 1px solid #ccc;
    }

    &:disabled + span::before {
      color: #ccc;
    }

    &:checked + span::before{
      content: "\2713";
      position: absolute;
      left: 2px;
    }
  }

}

@mixin sparsed-button {
  padding: 10px 12px;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-family: $proxima-font-family;
  letter-spacing: 2px;
  outline: none;
  text-decoration: none;
  border: 1px solid #000;

  &:hover, &:focus {
    text-decoration: none;
  }

  @media (min-width: $m-w-4) {
    font-size: 16px;
  }
}

@mixin regular-text {
  font-family: $proxima-font-family;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: #000;
  margin: 0;
  padding: 0;

  @media (min-width: $m-w-4) {
    font-size: 18px;
    line-height: 28px;
  }
}

@mixin section-title {
  color: #000;
  padding: 0;
  font-size: 24px;
  font-family: $ff-crimson-font-family;
  letter-spacing: 0.48px;
  font-weight: 400;
  padding: 27px 0 3px 0;

  @media (min-width: $w-1-mobile) {
    padding: 57px 0 3px 0;
  }

  @media (min-width: $m-w-3) {
    padding: 67px 0 3px 0;
  }

  @media (min-width: $m-w-4) {
    padding: 87px 0 3px 0;
  }
}

@mixin generic-form {
  margin: 0;
  label {
    font-weight: bold;
    display: flex;
    align-items: center;

    &.optional {
      font-weight: normal;
    }

    &.optionalLow {
      font-weight: normal;
      color: #7f7f7f;
    }
  }

  input[type=checkbox] {
    margin: 0 6px 0 0;
  }

  input[type=number], input[type=text], input[type=email], input[type=password], select, textarea {
    font-family: $proxima-font-family;
    height: 40px;
    border: 1px solid #c6c6c6;
    box-shadow: none;
    margin: 0;
    border-radius: 0;
    width: 100%;
    font-size: 16px;

    &:active, &:focus {
      border: 1px solid #000;
    }
  }

  .error {
    color: red;
  }


  .guestRow {
    margin: $row-margin 0;
  }

  .row, .submitRow {
    display: flex;
    margin-bottom: $row-margin;
    width: 100%;
    flex-direction: column;

    @media (min-width: $w-1-mobile) {
      flex-direction: row;
      width: 80%;
    }
  }

  .submitRow {
    flex-direction: row-reverse;

    @media (min-width: $w-1-mobile) {
      padding-right: 2 * $row-margin;
    }
  }

  .simpleWideField {
    flex: 1;
    flex-direction: column;
    margin-bottom: $row-margin;
  }

  .field, .wideField {
    flex: 1;
    flex-direction: column;
    margin-bottom: $row-margin;

    @media (min-width: $w-1-mobile) {
      padding-right: 2 * $row-margin;
      margin-bottom: 0;
    }

    a {
      color: #000;
      text-decoration: underline;
    }
  }

  .lowI {
    font-size: 12px;
    color: #7f7f7f;
    a {
      color: #7f7f7f;
    }
  }

  .field {
    @media (min-width: $w-1-mobile) {
      max-width: 50%;
    }
  }
}

@mixin headerSubtitleText {
  font-family: $proxima-font-family;
  line-height: 120%;
  font-weight: normal;
  margin: 0;
  padding: 0;
  font-size: 14px;
  letter-spacing: 0.02em;

  @media (min-width: $w-1-mobile) {
    line-height: 16px;
    font-size: 14px;
  }

  @media (min-width: $m-w-2) {
    font-size: 16px;
    line-height: 19px;
  }

  @media (min-width: $m-w-3) {
    font-size: 17px;
    line-height: 21px;
  }

  @media (min-width: $m-w-4) {
    font-size: 20px;
    line-height: 24px;
  }
}

@mixin navigation-font-sizes {
  font-size: 12px;
  font-family: $proxima-font-family;
  letter-spacing: 1.2px;

  @media (min-width: $m-w-2) {
    font-size: 14px;
  }

  @media (min-width: $m-w-3) {
    font-size: 18px;
  }

  @media (min-width: $m-w-4) {
    font-size: 20px;
  }

  @media (max-width: $w-1-mobile - 1) {
    font-size: 14px;
  }
}
