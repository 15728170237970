@use "arts/assets/variables" as *;

.modal {
  width: 698px;
  @include modal-window;
  padding: 60px 21px 60px;
}

.about {
  padding: 0 80px;
  @include page-title;

  @media (max-width: 450px) {
    padding: 0 20px;
  }

  h1 {
    font-family: $proxima-font-family;
    font-weight: 300;
    justify-content: center;
    font-size: 18px;
  }

  p {
    margin: 0 0;
    text-align: center;
    line-height: 150%;
    font-size: 14px;
    @media (max-width: 450px) {
      font-size: 11px;
      line-height: 100%;
    }
  }

  a {
    color: #000;
    text-decoration: underline;
  }

}

.actions {
  margin-top: 28px;
  text-align: center;

  button {
    display: block;
  }

  .manage {
    padding: 0;
    margin: 0;
    border: none;
    background: #fff;
    text-decoration: underline;
    color: #000;
    margin: 0 auto;
  }

  .allowAll {
    @include action-button;
    border: 1px solid #000 !important;
    width: 240px;
    min-height: 44px;
    margin: 0 auto 24px auto !important;
  }
}

.details {
  margin-top: 14px;
  border-top: 2px solid #000;
  padding-top: 21px;

  div {
    margin-bottom: 14px;
  }

  @media (max-width: 450px) {
    font-size: 11px;
    line-height: 100%;
  }

  label {
    @include custom-chekbox;
    font-weight: bold;
  }

  form {
    margin: 0 24px;
    @media (max-width: 450px) {
      margin: 0;
    }
  }
}
